import React, { useEffect } from 'react'

import ImageWebp from 'src/components/ImageWebp'
import useDataLayer from 'src/hooks/useDataLayer/dataLayerBody'

import { initTheme, Theme } from '@interco/inter-ui'
import { IDataLayerParams } from 'src/hooks/useDataLayer/typesUseDataLayer'
import useWidth from 'src/hooks/window/useWidth'
import { WIDTH_MD } from 'src/styles/breakpoints'
import { ViewsEnum } from '../../components/_ModalContentFactory'
import { ModalContentDispatchT, useModalContent } from '../../context/_ModalContentContext'
import { SideModalDispatchT, useSideModal } from '../../context/_SideModalContext'
import { operatorsList } from './_operatorsList'
import { Card, Section } from './style'

export const Bonus = ({ sectionId }: {sectionId: string}) => {
  const [ sendDatalayerEvent ] = useDataLayer()
  const { setModalContentView }: ModalContentDispatchT = useModalContent()
  const { setIsModalOpen }: SideModalDispatchT = useSideModal()

  const arrayNumbers: number[] = [
    116, 117, 174, 192,
  ]

  const arrayHeight: number[] = [
    80, 80, 136, 136,
  ]

  useEffect(() => {
    initTheme(Theme.PF)
  })

  const windowWidth = useWidth(300)

  const handleClick = (provider: string) => {
    const dataLayer: IDataLayerParams = {
      section: 'dobra_2',
      section_name: 'Ganhe GBs de bônus na sua recarga de celular',
      element_action: 'click button',
      element_name: provider,
    }

    sendDatalayerEvent(dataLayer)
    setIsModalOpen(true)
    setModalContentView(ViewsEnum.primaryFlow)
  }

  return (
    <Section id={sectionId}>
      <div className='container'>
        <div className='row mb-3 mb-md-5'>
          <div className='col-12'>
            <h2 className='font-sora fw-600 fs-24 lh-30 fs-lg-32 lh-lg-40 fs-xl-40 lh-xl-50 text-grayscale--500 mb-3 mr-n3 mr-md-0 text-md-center'>
              Ganhe GBs de bônus na sua recarga de celular
            </h2>
            <p className='fs-14 lh-17 fs-lg-16 lh-lg-19 fs-xl-18 lh-xl-22 text-grayscale--500 mb-0 text-md-center'>
              A recarga para sua operadora será feita diretamente no Super App Inter e você ainda aproveita promoções exclusivas!
            </p>
          </div>
        </div>

        <div className='row'>
          {operatorsList.map((operator: typeof operatorsList[0]) => (
            <div className='col-6 col-md-3 px-lg-3' key={operator.title}>
              <Card>
                <div>
                  <ImageWebp
                    pathSrc={operator.image}
                    altDescription={operator.alt}
                    arrayNumbers={arrayNumbers}
                    arrayNumbersHeight={arrayHeight}
                  />
                  <h3 className='fs-18 lh-22 fw-700 text-grayscale--500 text-center mx-n1 mb-3 mb-xl-4'>{operator.title}</h3>
                </div>
                {windowWidth >= WIDTH_MD ? (
                  <button
                    className='btn btn-orange--extra text-none w-100 mw-100 px-1'
                    onClick={() => handleClick(operator.title)}
                  >
                    Fazer recarga
                  </button>
                ) : (
                  <a
                    href='https://inter-co.onelink.me/Qyu7/f9qebj1r'
                    target='_blank'
                    className='btn btn-orange--extra text-none w-100 mw-100 px-1'
                    onClick={() => {
                      sendDatalayerEvent({
                        section: 'dobra_2',
                        section_name: 'Ganhe GBs de bônus na sua recarga de celular',
                        element_action: 'click button',
                        element_name: 'Fazer recarga',
                        redirect_url: 'https://inter-co.onelink.me/Qyu7/f9qebj1r',
                      })
                    }} rel='noreferrer'
                  >
                    Fazer recarga
                  </a>
                )}
              </Card>
            </div>
          ))}
        </div>
      </div>
    </Section>
  )
}
